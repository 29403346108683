import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto kdf compare`}</strong>{` -- compare a plaintext value (e.g., a password) and a hash`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto kdf compare <phc-hash> [<input>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`The 'step crypto kdf compare' command compares a plaintext value (e.g., a
password) with an existing KDF password hash in PHC string format. The PHC
string input indicates which KDF algorithm and parameters to use.`}</p>
    <p>{`  If the input matches `}<inlineCode parentName="p">{`phc-hash`}</inlineCode>{` the command prints a human readable message
indicating success to STDERR and returns 0. If the input does not match an
error will be printed to STDERR and the command will exit with a non-zero
return code.`}</p>
    <p>{`  If this command is run without the optional `}<inlineCode parentName="p">{`input`}</inlineCode>{` argument and STDIN is a
TTY (i.e., you're running the command in an interactive terminal and not
piping input to it) you'll be prompted to enter a value on STDERR. If STDIN is
not a TTY it will be read without prompting.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto kdf`}</strong>{`.`}</p>
    <p>{`POSITIONAL ARGUMENTS`}</p>
    <p><inlineCode parentName="p">{`phc-hash`}</inlineCode>{`
The KDF password hash in PHC string format.`}</p>
    <p><inlineCode parentName="p">{`input`}</inlineCode>{`
The plaintext value to compare with `}<inlineCode parentName="p">{`phc-hash`}</inlineCode>{`. `}<inlineCode parentName="p">{`input`}</inlineCode>{` is optional and its
use is not recommended. If this argument is provided the `}<strong parentName="p">{`--insecure`}</strong>{` flag
must also be provided because your (presumably secret) `}<inlineCode parentName="p">{`input`}</inlineCode>{` will likely be
logged and appear in places you might not expect. If omitted input is read
from STDIN.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      